<template>
  <v-app id="app">
    <v-navigation-drawer
      v-if="!hideSidebar"
      v-model="drawer"
      class="app-sidebar"
      app
      width="19.125rem"
      color="secondaryBackground"
      floating
      :permanent="!isFeatureEnabled('hiddenSidebar')"
    >
      <div class="d-flex align-center justify-start px-3" :style="`height: ${navBarHeight}px;`">
        <StoryCityIconBtn
          v-if="isFeatureEnabled('hiddenSidebar')"
          class="mr-6"
          color="background"
          @click="() => (drawer = !drawer)"
        >
          <img
            :src="require('@/assets/icons/menu.svg')"
            :alt="$t('alts.menuIcon')"
            class="menu-icon"
          />
        </StoryCityIconBtn>
        <router-link style="height: auto; line-height: 1" to="/">
          <img
            :src="require('@/assets/storycity_logo_text.svg')"
            :alt="$t('alts.scLogo')"
            contain
          />
        </router-link>
      </div>
      <router-view name="sidebar" />
    </v-navigation-drawer>
    <v-app-bar
      app
      permanent
      clipped-left
      class="app-navbar"
      :height="navBarHeight"
      flat
      color="secondaryBackground"
    >
      <StoryCityIconBtn
        v-if="!hideSidebar && isFeatureEnabled('hiddenSidebar')"
        class="mr-6"
        color="background"
        @click="() => (drawer = !drawer)"
      >
        <img
          :src="require('@/assets/icons/menu.svg')"
          :alt="$t('alts.menuIcon')"
          class="menu-icon"
        />
      </StoryCityIconBtn>
      <router-link style="height: auto; line-height: 1" to="/">
        <img
          :src="require('@/assets/storycity_logo_text.svg')"
          :alt="$t('alts.scLogo')"
          contain
        />
      </router-link>
      <v-spacer />
      <v-btn v-if="isAdmin" class="mx-5" to="/admin">
        {{ $t("admin.admin") }}
      </v-btn>
      <div v-if="isCreator" style="position: relative">
        <CreateStoryButton />
        <div
          style="
            height: 1px;
            width: 1px;
            position: absolute;
            top: 55px;
            left: 50%;
          "
          id="create-story-button"
        />
      </div>
      <v-btn
        v-else-if="user"
        :to="{ name: 'become-creator' }"
        class="mx-5"
        color="primary"
        >{{ $t("becomeACreator.title") }}</v-btn
      >
      <router-link
        v-if="isCreator"
        class="creator-menu-text ml-7 mr-13"
        style="text-decoration: none"
        to="/creator-dashboard"
        >{{ $t("creator.creatorMenu") }}</router-link
      >
      <AvatarMenu />
    </v-app-bar>
    <v-container
      fluid
      :class="
        !hideSidebar && drawer ? 'main-container sidebar' : 'main-container'
      "
    >
      <router-view />
    </v-container>
    <ErrorSnackbar
      v-model="snackbar"
      :errorLabel="$t('serverError')"
      :errorMessage="
        $te('errorCodes.' + errorCode)
          ? $t('errorCodes.' + errorCode)
          : errorMessage
      "
    />
  </v-app>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import ErrorSnackbar from "@/components/Reusable/ErrorSnackbar";
import CreateStoryButton from "@/components/CreatorTools/CreateStoryButton";
import hasLoggedInUser from "@/mixins/hasLoggedInUser";
import AvatarMenu from "@/components/User/AvatarMenu";
import logout from "@/logout";
import StoryCityIconBtn from "@/components/Buttons/StoryCityIconBtn.vue";
import featureFlags from "@/mixins/featureFlags";

const NAVBAR_HEIGHT = 88;

export default {
  mixins: [hasLoggedInUser, featureFlags],
  components: {
    AvatarMenu,
    CreateStoryButton,
    ErrorSnackbar,
    StoryCityIconBtn,
  },
  data() {
    return {
      snackbar: false,
      drawer: true,
    };
  },
  watch: {
    snackbar(newValue, oldValue) {
      if (newValue == false && oldValue == true) {
        setTimeout(() => {
          this.setErrorCode("");
          this.setErrorMessage("");
        }, 300);
      }
    },
    errorMessage(newErrorMessage) {
      if (newErrorMessage) {
        this.snackbar = true;
      }
    },
    errorCode(newErrorCode) {
      if (newErrorCode) {
        this.snackbar = true;
      }
    },
  },
  computed: {
    hideSidebar() {
      for (const matchedRoute of this.$route.matched) {
        if (
          matchedRoute &&
          matchedRoute.meta &&
          matchedRoute.meta.hideSidebar
        ) {
          return true;
        }
      }
      return false;
    },
    navBarHeight() {
      return NAVBAR_HEIGHT;
    },
    ...mapGetters("serverError", ["errorCode", "errorMessage"]),
  },
  methods: {
    ...mapMutations("auth", ["setMe"]),
    ...mapMutations("serverError", ["setErrorCode", "setErrorMessage"]),
    logout() {
      logout();
    },
  },
};
</script>

<style lang="scss" scoped>
.app-sidebar {
  border-radius: 0px 30px 30px 0px;
  box-shadow: 0px 5px 14.66667px 0px rgba(0, 0, 0, 0.06),
    0px 0.66667px 6.66667px 0px rgba(0, 0, 0, 0.04);
}

.app-sidebar .v-toolbar__content {
  padding: 0px;
}

.app-navbar {
  height: 88px;
  overflow: hidden;
}
.main-container {
  margin-top: 88px;
  padding: 0px 60px;
  transition: padding 0.3s ease-in-out;
  flex: 1;
}

.sidebar {
  padding-left: 366px;
}

.v-list .v-list-item {
  border-left: 5px solid transparent;
}

.menu-icon {
  width: 32px;
  height: 32px;
}

.creator-menu-text {
  color: var(--v-secondaryText-base);
  font-size: 18px;
  font-weight: 700;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .main-container {
    padding: 0px 20px;
  }
}
</style>
