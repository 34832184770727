export default {
  computed: {
    featureToggles() {
      return {
        hiddenSidebar: process.env.VUE_APP_FEATURE_TOGGLE_HIDDEN_SIDEBAR,
        yourStories: process.env.VUE_APP_FEATURE_TOGGLE_YOUR_STORIES,
        analytics: process.env.VUE_APP_FEATURE_TOGGLE_ANALYTICS,
        comments: process.env.VUE_APP_FEATURE_TOGGLE_COMMENTS,
        yourIncome: process.env.VUE_APP_FEATURE_TOGGLE_YOUR_INCOME,
        creatorSettings: process.env.VUE_APP_FEATURE_TOGGLE_CREATOR_SETTINGS,
        profilePages: process.env.VUE_APP_FEATURE_TOGGLE_PROFILE_PAGES,
        storefront: process.env.VUE_APP_FEATURE_TOGGLE_STOREFRONT,
        storySorting: process.env.VUE_APP_FEATURE_TOGGLE_STORY_SORTING,
        draggableChapters: process.env.VUE_APP_FEATURE_TOGGLE_DRAGGABLE_CHAPTERS,
        enablePreview: process.env.VUE_APP_FEATURE_TOGGLE_ENABLE_PREVIEW,
        storySharing: process.env.VUE_APP_FEATURE_TOGGLE_STORY_SHARING,
        universalStories: process.env.VUE_APP_FEATURE_TOGGLE_UNIVERSAL_STORIES,
      };
    },
  },
  methods: {
    isFeatureEnabled(featureName) {
      const value = this.featureToggles[featureName];
      return this.checkTrue(value);
    },
    checkTrue(value) {
      return value == true || value == 'true';
    },
  },
};